@import 'bulma/bulma';
@import 'animate.css/animate.css';

@import 'components/loaders';
@import 'components/forms';



// === COMMON ===

.sign-in-box {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    user-select: none;
}



// === VUE TRANSITIONS ===

.slide-left-enter-active,
.slide-left-leave-active {
    transition: all .3s ease-out;
}

.slide-left-enter-from {
    opacity: 0;
    transform: translateX(50px);
}

.slide-left-leave-to {
    opacity: 0;
    transform: translateX(-50px);
}
