.loader {
	border: 3px solid #f3f3f3;
	border-top: 3px solid #d6d6d6;
	border-left: 3px solid #d6d6d6;
	border-radius: 50%;
	width: 25px;
	height: 25px;
	animation: spin .6s linear infinite;

	&--centered {
		margin: 0 auto;
	}
}

@keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
}
